import { axios } from '@/utils/request'
import {mockRepaymentReport, useMock} from "@/webpublicapi/_mock";

export function getAllOrderList (parameter = {}) {
  return axios({
    url: '/order/allOrderList',
    method: 'post',
    data: parameter
  })
}
export function getOrderDetail (parameter = {}) {
  return axios({
    url: '/order/detail',
    method: 'post',
    data: parameter
  })
}

export function getOrderInfoQryList (parameter = {}) {
  return axios({
    url: '/order/orderInfoQry',
    method: 'post',
    data: parameter
  })
}

export function createRepayUrlFunc (parameter = {}) {
  return axios({
    url: '/order/createRepayUrl',
    method: 'post',
    data: parameter
  })
}

export function repayStatusSync (parameter = {}) {
  return axios({
    url: '/statusSync/payIn',
    method: 'post',
    data: parameter
  })
}

export function payoutStatusSync (parameter = {}) {
  return axios({
    url: '/statusSync/payout',
    method: 'post',
    data: parameter
  })
}

export function createRepayOrderFunc (parameter = {}) {
  return axios({
    url: '/order/createRepayOrder',
    method: 'post',
    data: parameter
  })
}

export function getApplist (parameter = {}) {
  return axios({
    url: '/app/listALLSelect',
    method: 'post',
    data: parameter
  })
}
export function getApplistExcludingSubApp (parameter = {}) {
  return axios({
    url: '/app/listALLSelectExcludingSubApp',
    method: 'post',
    data: parameter
  })
}
export function countLoanDataWithDateForApp (parameter = {}) {
  return axios({
    url: '/platform/order/countLoanDataWithDateForApp',
    method: 'post',
    data: parameter
  })
}

export function reborrowCountAll (parameter = {}) {
  return axios({
    url: '/order/reborrowCountAll',
    method: 'post',
    data: parameter
  })
}
export function getRepaymentReportRateReal (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportRateReal',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportForDepositProfit (parameter = {}) {
  return axios({
    url: '/statistics/platform/repaymentReportForDepositProfit',
    method: 'post',
    data: parameter
  })
}

export function repaymentReportAmtRateReal (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportAmtRateReal',
    method: 'post',
    data: parameter
  })
}

export function getOrderDetailForCollection (parameter = {}) {
  return axios({
    url: '/order/detailForCollection',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportForBeginDate (parameter = {}) {
  return axios({
    url: '/statistics/platform/repaymentReportForBeginDate',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportForInstallment (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/repaymentReportForBeginDate',
    method: 'post',
    data: parameter
  })
}

export function getCountDueReportWithDate (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/countDueReportWithDate',
    method: 'post',
    data: parameter
  })
}

export function getCountDueReportWithDateAmount (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/countDueReportWithDateForAmount',
    method: 'post',
    data: parameter
  })
}

export function getLoanOrderList (parameter = {}) {
  return axios({
    url: '/order/loanOrderList',
    method: 'post',
    data: parameter
  })
}
export function getPendingOrderList (parameter = {}) {
  return axios({
    url: '/order/pendingOrderlist',
    method: 'post',
    data: parameter
  })
}
export function getOverdueList (parameter = {}) {
  return axios({
    url: '/order/overdueList',
    method: 'post',
    data: parameter
  })
}
export function getRepayPlanList (parameter = {}) {
  return axios({
    url: '/repayPlan/list',
    method: 'post',
    data: parameter
  })
}
export function getExamineLogList (parameter = {}) {
  return axios({
    url: '/examine/log/list',
    method: 'post',
    data: parameter
  })
}

export function getCollectionLogList (parameter = {}) {
  return axios({
    url: '/collectionLog/list',
    method: 'post',
    data: parameter
  })
}

export function getOrderNumberList(parameter = {}) {
  return axios({
    url: '/orderNumberInfo/selectByOrderInfoId',
    method: 'post',
    data: parameter
  })
}

export function getOrderImageList(parameter = {}) {
  return axios({
    url: '/orderImage/selectByOrderInfoId',
    method: 'post',
    data: parameter
  })
}


export function getAddressBookList (parameter = {}) {
  return axios({
    url: '/customer/addressBook',
    method: 'post',
    data: parameter
  })
}

export function allowLending (parameter = {}) {
  return axios({
    url: '/order/lending',
    method: 'post',
    data: parameter
  })
}

export function getRepayRecordList (parameter = {}) {
  return axios({
    url: '/repayPlan/repayRecordList',
    method: 'post',
    data: parameter
  })
}

export function getRepayRecordListV2 (parameter = {}) {
  return axios({
    url: '/repayPlan/repayRecordListV2',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReport (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepaymentReport)
  return axios({
    url: '/statistics/repaymentReport',
    method: 'post',
    data: parameter
  })
}

export function downRepaymentReportExcel (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportExcel',
    method: 'get',
    data: parameter
  })
}

export function paymentsLink (parameter = {}) {
  return axios({
    url: '/order/paymentsLink',
    method: 'post',
    data: parameter
  })
}

export function repayStatusCheck (parameter = {}) {
  return axios({
    url: '/platform/order/repaymentStatusCheck',
    method: 'post',
    data: parameter
  })
}

export function reductionLateFee (parameter = {}) {
  return axios({
    url: '/order/reductionLateFee',
    method: 'post',
    data: parameter
  })
}

export function reductionCapitalFee (parameter = {}) {
  return axios({
    url: '/order/reductionCapitalFee',
    method: 'post',
    data: parameter
  })
}



export function repayOfflineAll (parameter = {}) {
  return axios({
    url: '/order/repayOfflineAll',
    method: 'post',
    data: parameter
  })
}

export function installmentRepayOffline (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/offlineRepay',
    method: 'post',
    data: parameter
  })
}
export function installmentDeduceLateFee (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/deduceLateFee',
    method: 'post',
    data: parameter
  })
}

export function getPlatformLoanOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/loanOrderList',
    method: 'post',
    data: parameter
  })
}

export function reductionCapitalFeeList (parameter = {}) {
  return axios({
    url: '/platform/order/reductionCapitalFeeList',
    method: 'post',
    data: parameter
  })
}



export function getPlatformLoanOrderListF (parameter = {}) {
  return axios({
    url: '/platform/order/loanOrderListF',
    method: 'post',
    data: parameter
  })
}




export function getModifyCollectorOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/modifyCollectorOrderList',
    method: 'post',
    data: parameter
  })
}

export function getPaydetailOrderList (parameter = {}) {
  return axios({
    url: '/order/paydetailOrderList',
    method: 'post',
    data: parameter
  })
}
export function getInstallPaydetailOrderList (parameter = {}) {
  return axios({
    url: '/order/installPaydetailOrderList',
    method: 'post',
    data: parameter
  })
}
export function getRepayOrderList (parameter = {}) {
  return axios({
    url: '/order/repayOrderList',
    method: 'post',
    data: parameter
  })
}

export function getInstallRepayOrderList (parameter = {}) {
  return axios({
    url: '/order/installRepayOrderList',
    method: 'post',
    data: parameter
  })
}

export function getPlatformAllOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/allOrderList',
    method: 'post',
    data: parameter
  })
}

export function getInstallmentPlatformAllOrderList (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/allOrderList',
    method: 'post',
    data: parameter
  })
}

export function getInstallmentPlatformAllOrderListPrd (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/allOrderListPrd',
    method: 'post',
    data: parameter
  })
}

export function createInstallmentRepayUrl (parameter = {}) {
  return axios({
    url: '/order/createInstallmentRepayUrl',
    method: 'post',
    data: parameter
  })
}

export function installmentLoanOrder (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/loanOrder',
    method: 'post',
    data: parameter
  })
}

export function installmentLoanOrderFailed (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/loanOrderFailed',
    method: 'post',
    data: parameter
  })
}

export function installmentLoanOrderLoaning (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/loanOrderLoaning',
    method: 'post',
    data: parameter
  })
}

export function getPlatformPendingOrderlist (parameter = {}) {
  return axios({
    url: '/platform/order/pendingOrderlist',
    method: 'post',
    data: parameter
  })
}

export function getPlatformOverdueList (parameter = {}) {
  return axios({
    url: '/platform/order/overdueList',
    method: 'post',
    data: parameter
  })
}

export function countPlatformLoanDataWithDate (parameter = {}) {
  return axios({
    url: '/platform/order/countLoanDataWithDate',
    method: 'post',
    data: parameter
  })
}

export function countInstallmentPlatformLoanDataWithDate (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/countLoanDataWithDate',
    method: 'post',
    data: parameter
  })
}

export function countOrderRepayData (parameter = {}) {
  return axios({
    url: '/platform/order/countOrderRepayData',
    method: 'post',
    data: parameter
  })
}

export function getPlatformRepaymentReport (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepaymentReport)
  return axios({
    url: '/statistics/platform/repaymentReport',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportForAmount (parameter = {}) {
  return axios({
    url: '/statistics/platform/repaymentReportForAmount',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportRate (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportRate',
    method: 'post',
    data: parameter
  })
}

export function getTransformingDataList (parameter = {}) {
  return axios({
    url: '/statistics/transformingDataList',
    method: 'post',
    data: parameter
  })
}


export function extendOffline (parameter = {}) {
  return axios({
    url: '/order/extendOffline',
    method: 'post',
    data: parameter
  })
}

export function modifyCollector (parameter = {}) {
  return axios({
    url: '/order/modifyCollector',
    method: 'post',
    data: parameter
  })
}

export function countLoanDataWithDate (parameter = {}) {
  return axios({
    url: '/order/countLoanDataWithDate',
    method: 'post',
    data: parameter
  })
}

export function reborrowCount (parameter = {}) {
  return axios({
    url: '/order/reborrowCount',
    method: 'post',
    data: parameter
  })
}

export function reborrowCountForSubmit (parameter = {}) {
  return axios({
    url: '/order/reborrowCountBySubmitDate',
    method: 'post',
    data: parameter
  })
}

export function getOverdueReportRate (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepaymentReport)
  return axios({
    url: '/statistics/overdueReportRate',
    method: 'post',
    data: parameter
  })
}

export function downOverdueReportExcel (parameter = {}) {
  return axios({
    url: '/statistics/overdueReportRateExcel',
    method: 'get',
    data: parameter
  })
}

export function getPlatformPartRepayOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/partRepayList',
    method: 'post',
    data: parameter
  })
}
export function partRepayOfflineAll (parameter = {}) {
  return axios({
    url: '/order/partRepayOfflineAll',
    method: 'post',
    data: parameter
})
}

export function getRefundTradeFlowList (parameter = {}) {
  return axios({
    url: '/order/refundTradeFlowList',
    method: 'post',
    data: parameter
  })
}

export function refundTrade (parameter = {}) {
  return axios({
    url: '/order/refundTrade',
    method: 'post',
    data: parameter
  })
}
export function editOrderStatusForTest (parameter = {}) {
  return axios({
    url: '/order/test/modifyOrderStatus',
    method: 'post',
    data: parameter
  })
}

export function editInstallmentOrderStatusForTest (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/modifyOrderRepayPlanStatus',
    method: 'post',
    data: parameter
  })
}

export function editInstallOrderStatusMainForTest (parameter = {}) {
  return axios({
    url: '/platform/installmentOrder/modifyOrderStatus',
    method: 'post',
    data: parameter
  })
}

export function clearOrderForTest (parameter) {
  return axios({
    url: '/order/test/clearOrderData',
    method: 'post',
    data: parameter
  })
}

export function getAppAllCountForDate (parameter = {}) {
  return axios({
    url: '/statistics/appAllCountForDate',
    method: 'post',
    data: parameter
  })
}

export function getAppAllCount (parameter = {}) {
  return axios({
    url: '/statistics/appAllCount',
    method: 'post',
    data: parameter
  })
}

export function getConvertData (parameter = {}) {
  return axios({
    url: '/statistics/convertData',
    method: 'post',
    data: parameter
  })
}

export function getConvertInstallData (parameter = {}) {
  return axios({
    url: '/statistics/convertInstallData',
    method: 'post',
    data: parameter
  })
}

export function getConvertRealData (parameter = {}) {
  return axios({
    url: '/statistics/convertRealData',
    method: 'post',
    data: parameter
  })
}

export function getAppAllCountPartner (parameter = {}) {
  return axios({
    url: '/statistics/appAllCountPartner',
    method: 'post',
    data: parameter
  })
}

export function getMedias (parameter = {}) {
  return axios({
    url: '/statistics/medias',
    method: 'post',
    data: parameter
  })
}

export function getOrderAllCount (parameter = {}) {
  return axios({
    url: '/statistics/orderAllCount',
    method: 'post',
    data: parameter
  })
}

export function getReApplyOrderCount (parameter = {}) {
  return axios({
    url: '/platform/order/reApplyOrderCount',
    method: 'post',
    data: parameter
  })
}

export function getOrderCountByLoanCount (parameter = {}) {
  return axios({
    url: '/order/countOrderByLoanCount',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentYYReportForBeginDate (parameter = {}) {
  return axios({
    url: '/statistics/platform/repaymentYYReportForBeginDate',
    method: 'post',
    data: parameter
  })
}
export function collectionCountByDay (parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/collectionCountByDay',
    method: 'post',
    data: parameter
  })
}
export function collectionCountTotalByDay(parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/collectionCountTotalByDay',
    method: 'post',
    data: parameter
  })
}

export function getAppAllCountForAppssid (parameter = {}) {
  return axios({
    url: '/statistics/appAllCountForAppssid',
    method: 'post',
    data: parameter
  })
}

export function updateOverdueDaysById (orderInfoId, overduedays) {
  return axios({
    url: '/order/updateOverdueDaysById?orderInfoId=' + orderInfoId + '&overdueDays=' + overduedays,
    method: 'post'
  })
}

export function syncData (parameter = {}) {
  return axios({
    url: '/statistics/syncData',
    method: 'post',
    data: parameter
  })
}
export function syncDataPartner (parameter = {}) {
  return axios({
    url: '/statistics/syncDataPartner',
    method: 'post',
    data: parameter
  })
}

export function findLoanStatusCount (parameter = {}) {
  return axios({
    url: '/platform/order/findLoanStatusCount',
    method: 'post',
    data: parameter
  })
}

export function reborrowCountInstallment (parameter = {}) {
  return axios({
    url: '/order/reborrowCountInstallment',
    method: 'post',
    data: parameter
  })
}

export function reborrowCountForSubmitInstallment (parameter = {}) {
  return axios({
    url: '/order/reborrowCountBySubmitDateInstallment',
    method: 'post',
    data: parameter
  })
}

export function appAllCountForChannel (parameter = {}) {
  return axios({
    url: '/statistics/appAllCountForChannel',
    method: 'post',
    data: parameter
  })
}

export function deduceInstallmentLateAmount(data = {}) {
    return axios({
        url: 'platform/installmentOrder/deduceLateFee',
        method: 'post',
        data
    })
}
export function reborrowCountForAppInstallment (parameter = {}) {
  return axios({
    url: '/order/reborrowCountByAppInstallment',
    method: 'post',
    data: parameter
  })
}